import { h, JSX } from "preact";
import { Local, LocalData } from "@_types/Local";
import SectionNutrition from "@components/SectionNutrition";
import SectionFood from "@components/SectionFood";
import formatLocalName from "@lib/formatLocalName";
import Divider from "@components/Divider";
import { ButtonIconLink } from "@components/ButtonIcon";

type Props = {
  openModal: () => void;
  local: Local;
  localData: LocalData;
  year: number;
};

const urlBase = `https://s3.sa-east-1.amazonaws.com/panorama.obesidadeinfantil.org.br/v12/desiderata-2024-dez`;

export default function AppMain(props: Props): JSX.Element {
  const { local, localData, year, openModal } = props;

  const urlDownload = `${urlBase}-${local.ibge}.xlsx`;
  const localName = formatLocalName(local);

  const localPreposition = local.preposition === 'no' ? 'do' : (local.preposition === 'na' ? 'da' : 'de');
  return (
    <main>
      <SectionNutrition local={local} nutritionClasses={localData.nutritionClasses} year={year} openModal={openModal} />
      <Divider />
      <SectionFood local={local} nutritionFood={localData.nutritionFood} year={year} />
      <Divider />

      <header className="header-title container">
        <div>
          <h1>
            BAIXAR DADOS {localPreposition.toLocaleUpperCase()}{" "}
            <strong>{localName.toLocaleUpperCase()}</strong> - <strong>TODOS OS ANOS</strong>
          </h1>
        </div>
      </header>
      <div className="container">
        <p style={"margin-top: 8px; margin-bottom: 16px"}>
          Planilha com dados {localPreposition} {local.name} para todos os anos disponíveis, incluindo estado
          nutricional, consumo alimentar e taxas de cobertura.
        </p>

        <ButtonIconLink address={urlDownload} label={'Baixar Planilha'} icon={"excel"} theme={'orange'} />
      </div>
    </main>
  );
}
