import { Age, NutritionFoodID } from "@_types/Enums";
import { NutritionFoodDictionaryItems } from "@_types/NutritionFood";

const breakLine = `
 &nbsp;
 &nbsp;
`;

const aleitamentoExclusivo = `
Esse indicador tem o objetivo de estimar a proporção de crianças de até 6 meses que receberam exclusivamente leite humano no dia anterior à avaliação de acompanhamento do SISVAN.
${breakLine}   
São consideradas todas as crianças na respectiva faixa etária com resposta **"Sim"** à pergunta **"A criança ontem tomou leite do peito?"**, desde que seja assinalado *"Não"* para qualquer outra pergunta de consumo alimentar.
${breakLine}   
**Orientação**: até o 6º mês de idade a criança deve receber apenas leite humano. Não é necessário oferecer qualquer outro alimento, como outro leite, água ou chás. O leite humano é tudo que a criança precisa.
`;

const aleitamentoContinuado = `
Esse indicador tem o objetivo de definir a proporção de crianças de de 6 meses até 2 anos de idade que receberam leite humano no dia anterior à avaliação de acompanhamento do SISVAN.
${breakLine}   
São consideradas todas as crianças todas as crianças na respectiva faixa etária com resposta **"Sim"** à questão **"Ontem, a criança tomou leite do peito?"**.
${breakLine}   
**Orientação**: A continuidade do aleitamento até os 2 anos ou mais amplia a disponibilidade de energia e de micronutrientes da alimentação, particularmente o ferro. Além disso, a partir dos 6 meses, as necessidades nutricionais da criança aumentam, devendo a mãe ou cuidador apresentar, além do leite humano, outros alimentos.
`;

const diversidadeAlimentar = `
Esse indicador tem o objetivo de definir a proporção de crianças de de 6 meses até 2 anos de idade que receberam **seis grupos alimentares** no dia anterior à avaliação avaliação de acompanhamento do SISVAN.
${breakLine}   
São consideradas todas as crianças na respectiva faixa etária que consumiram os seis grupos de alimentos relacionados:
* Leite humano ou outro leite que não do peito;
* Mingau com leite ou iogurte;
* Frutas, legumes e verduras;
* Vegetais ou frutas de cor alaranjada e folhas verdes escuras;
* Carnes e ovos;
* Feijão;
* Cereais e tubérculos (arroz, batata, inhame, aipi/macaxeira/madioca, farinha ou macarrão - sem ser instantâneo).
**Orientação**: Os alimentos devem ser oferecidos três vezes ao dia (papa salgada e papa de fruta). A papa salgada deve conter um alimento do grupo dos cereais e tubérculos, um dos legumes e verduras, um de origem animal e um das leguminosas.
`;

const frequencia = `
Esse indicador tem o objetivo de definir a proporção de crianças de de 6 meses até 2 anos de idade que receberam comida de sal com frequência e consistência adequadas para a idade no dia anterior à avaliação de acompanhamento do SISVAN.
${breakLine}   
São consideradas todas as crianças na respectiva faixa etária, de acordo com as observações a seguir:
* Se criança de 6 a 6 meses e 29 dias, considerar o consumo de pelo menos **uma vez** ao dia de comida de sal com consistência normal (em pedaços) ou amassada;
* Se criança de 7 a 23 meses e 29 dias, considerar o consumo de pelo menos **duas vezes** ao dia de comida de sal com consistência normal (em pedaços) ou amassada.
**Orientação**: Os alimentos devem ser oferecidos três vezes ao dia (papa salgada e papa de fruta). Frutas como laranja, limão, acerola, goiaba, manga são importantes fontes de vitamina C e devem ser oferecidas junto ou após a refeição principal para aumentar a absorção de ferro. É fundamental também oferecer duas frutas diferentes por dia, para que a criança conheça o sabor de frutas variadas.
`;

const ferro = `
Esse indicador tem o objetivo de definir a proporção de crianças de de 6 meses até 2 anos de idade que receberam alimentos ricos em ferro no dia anterior à avaliação de acompanhamento do SISVAN.
${breakLine}   
São consideradas todas as crianças na respectiva faixa etária que consumiram **os três tipos de alimentos** relacionados:
* Carnes (boi, frango, peixe, porco, miúdos, outras) ou ovo;
* Fígado; 
* Feijão.
**Orientação**: **As carnes e os ovos** são importantes fontes de proteína e ferro. São essenciais para o crescimento e o desenvolvimento da criança. A introdução do ovo aos 6 meses deve ser avaliada pelo profissional de saúde, levando em consideração o histórico de alergia alimentar na família. Oferecer a carne desfiada ou amassada, evitando liquidificar os alimentos. O consumo de **fígado** pode prevenir o surgimento da anemia ferropriva e também é importante fonte de vitamina A. Recomenda-se o consumo de fígado pelo menos uma vez na semana. O **feijão** é um tipo de leguminosa e é rico em proteína, contendo ainda fibra alimentar, vitaminas do complexo B, ferro, cálcio e outros minerais. É fundamental o consumo dos três tipos em conjunto.
`;

const vitaminaA = `
Esse indicador tem o objetivo de definir a proporção de crianças de de 6 meses até 2 anos de idade que receberam alimentos ricos em vitamina A no dia anterior à avaliação de acompanhamento do SISVAN.
${breakLine}   
São consideradas todas as crianças na respectiva faixa etária com resposta **"Sim"** à questão **"Ontem, a criança consumiu vegetal ou fruta de cor alaranjada (abóbora ou jerimum, cenoura, mamão, manga) ou folhas verde-escuras (couve, caruru, beldroega, bertalha, espinafre, mostarda)?"**
${breakLine}   
**Orientação**: Os alimentos como frutas e vegetais de cor alaranjada e folhas verde-escuras são importantes fontes de vitamina A. Podem-se citar como exemplo os alimentos: manga, mamão, abóbora ou jerimum, cenoura, couve, rúcula, agrião.
`;

const ultra = `
Esse indicador tem o objetivo de definir a proporção de crianças dias que consumiram alimentos ultraprocessados no dia anterior à avaliação de acompanhamento do SISVAN.
${breakLine}   
São consideradas todas as crianças na respectiva faixa etária que consumiram **pelo menos um** dos seguintes alimentos:
* Hambúrguer e/ou embutidos (presunto, mortadela, salame, linguiça, salsicha);
* Bebidas adoçadas (refrigerante, suco de caixinha, suco em pó, água de coco de caixinha, xaropes de guaraná/groselha, suco de fruta com adição de açúcar);
* Macarrão instantâneo, salgadinhos de pacote ou biscoitos salgados;
* Biscoito recheado, doces ou guloseimas (balas, pirulitos, chicletes, caramelo, gelatina).
`;

const hamburguer = `
Esse indicador tem o objetivo de definir a proporção de crianças dias consumiram hambúrguer e/ou embutidos no dia anterior à avaliação de acompanhamento do SISVAN.
${breakLine}   
São consideradas todas as crianças na respectiva faixa etária com resposta **"Sim"** à questão **"Ontem, a criança consumiu hambúrguer e/ou embutidos (presunto, mortadela, salame, linguiça, salsicha)?"**
${breakLine}   
**Orientação**: Os produtos derivados de carne (embutidos, hambúrgueres, salsichas, salames, linguiças etc.) têm, em geral, quantidades elevadas de gordura saturada e sódio, devendo ser evitados, pois seu consumo elevado é considerado fator de risco para várias doenças.
`;

const refri = `
Esse indicador tem o objetivo de definir a proporção de crianças dias consumiram bebidas adoçadas no dia anterior à avaliação de acompanhamento do SISVAN.
${breakLine}   
São consideradas todas as crianças na respectiva faixa etária com resposta **"Sim"** à questão **"Ontem, a criança consumiu bebidas adoçadas (refrigerante, suco de caixinha, suco em pó, água de coco de caixinha, xaropes de guaraná/groselha, suco de fruta com adição de açúcar)?"**
${breakLine}   
**Orientação**: Bebidas industrializadas adoçadas possuem quantidades elevadas de açúcar e baixo teor de nutrientes importantes para manutenção da saúde. O consumo excessivo desses alimentos aumenta o risco de doenças como obesidade, hipertensão arterial, diabetes e doenças do coração
`;

const miojo = `
Esse indicador tem o objetivo de definir a proporção de crianças dias consumiram macarrão instantâneo, salgadinhos de pacote ou biscoitos salgados no dia anterior à avaliação de acompanhamento do SISVAN.
${breakLine}   
São consideradas todas as crianças na respectiva faixa etária com resposta **"Sim"** à questão **"Ontem, a criança consumiu macarrão instantâneo, salgadinhos de pacote ou biscoitos salgados?"**
${breakLine}   
**Orientação**: Produtos industrializados do tipo macarrão instantâneo, salgadinhos de pacote e biscoitos salgados são geralmente ricos em gorduras do tipo vegetal hidrogenada (gordura trans). Elas, embora sejam feitas a partir de óleos vegetais, são tão ou mais prejudiciais à saúde que as gorduras saturadas. Esses produtos também são ricos em sódio, o que os torna mais palatáveis e atrativos. Entretanto, seu consumo habitual e contínuo traz riscos para a saúde, pois pode favorecer a incidência de doenças do coração e a obesidade. 
`;

const doces = `
Esse indicador tem o objetivo de definir a proporção de crianças dias consumiram biscoito recheado, doces ou guloseimas no dia anterior à avaliação de acompanhamento do SISVAN.
${breakLine}   
São consideradas todas as crianças na respectiva faixa etária com resposta **"Sim"** à questão **"Ontem, a criança consumiu biscoito recheado, doces ou guloseimas (balas, pirulitos, chiclete, caramelo, gelatina)?"**
${breakLine}   
**Orientação**: Doces, guloseimas e biscoitos recheados são ricos em açúcar simples. O açúcar é utilizado para adoçar e preservar alimentos e bebidas industrializados, mas não é necessário ao organismo humano, pois a energia que fornece pode ser facilmente adquirida pelos grupos de alimentos fonte de carboidratos complexos (amidos). Mas o ser humano, desde que nasce, tem preferência por alimentos com sabor doce, o que explica o grande consumo e preferência por eles.
`;

const tresRefeicoes = `
Esse indicador que tem como objetivo apresentar a proporção de pessoas que realizam pelo menos três refeições ao dia, aqui consideradas o café da manhã, o almoço e o jantar.
${breakLine}   
São consideradas todas as pessoas na respectiva faixa etária que realizaram as três principais refeições (café da manhã, almoço e jantar).
${breakLine}   
**Orientação**: Sugere-se a alimentação com regularidade, em ambientes tranquilos, sem pressa e sem fatores que lhe tirem o foco, e, sempre que possível, em companhia agradável. Não parar para fazer as refeições com tranquilidade pode comprometer os mecanismos biológicos que sinalizam a saciedade. 
`;

const refeicoesTV = `
Esse indicador reflete a proporção de pessoas que costumam realizar as refeições assistindo à televisão, mexendo no computador e/ou celular.
${breakLine}   
São consideradas todas as pessoas na respectiva faixa etária com resposta **"Sim"** à questão **"Você tem costume de realizar as refeições assistindo à TV, mexendo no computador e/ou celular?"**
${breakLine}   
**Orientação**: É necessário realizar três refeições por dia (café da manhã, almoço e jantar) e, caso a pessoa sinta necessidade, intercalar com lanches, que pode ser o consumo de frutas frescas ou secas, leite ou iogurte e castanhas ou nozes.
`;

const feijao = `
Esse indicador reflete a proporção de pessoas que consumiram feijão no dia anterior à avaliação de acompanhamento do SISVAN.
${breakLine}   
São consideradas todas as pessoas na respectiva faixa etária com resposta **"Sim"** à questão **"Ontem, você consumiu feijão?"**
${breakLine}   
**Orientação**: O feijão é um tipo de leguminosa e é rico em proteína, fibra alimentar, vitaminas complexo B, ferro, cálcio e zinco. Estimule o seu consumo diário. A mistura feijão com arroz é uma combinação típica brasileira, sendo saudável e completa em proteínas. O uso de outros tipos de leguminosas, como soja, grão-de-bico, ervilha, lentilha e fava, também são aconselháveis.
`;

const frutas = `
Esse indicador que consiste na proporção de pessoas que consumiram fruta no dia anterior à avaliação de acompanhamento do SISVAN.
${breakLine}   
São consideradas todas as pessoas na respectiva faixa etária com resposta **"Sim"** à questão **"Ontem, você consumiu frutas frescas (não considerar suco de frutas)"**
${breakLine}   
**Orientação**: As frutas devem estar presentes diariamente nas refeições. Elas são ricas em vitaminas, minerais e fibras. A ingestão pode ser parte das refeições principais em saladas ou como sobremesas ou em refeições menores, entre as principais. É importante valorizar os produtos regionais e variar o tipo de fruta, preferindo aquelas da estação.
`;

const verduras = `
Esse indicador define a proporção de pessoas que consumiram verduras e legumes no dia anterior à avaliação de acompanhamento do SISVAN.
${breakLine}   
São consideradas todas as pessoas na respectiva faixa etária com resposta **"Sim"** à questão **"Ontem, você consumiu verduras e/ou legumes (não considerar batata, mandioca, aipim, macaxeira, cará e inhame)?"**
${breakLine}   
**Orientação**: Orientar que as verduras e/ou legumes devem estar presentes diariamente nas refeições, podendo ser consumidos de diversas formas, como em saladas, em preparações quentes, em sopas ou na forma de purê. Verduras e legumes são ricos em vitaminas, minerais e fibras e possuem baixa concentração de calorias
`;

const miojoTitle = "Consumo de macarrão instantâneo, salgadinhos de pacote ou biscoitos salgados";
const docesTitle = "Consumo de biscoito recheado, doces ou guloseimas";
const refriTitle = "Consumo de bebidas adoçadas";
const hamburguerTitle = "Consumo de hambúrguer e/ou embutidos";
const ultraTitle = "Consumo de alimentos ultraprocessados";
const tresRefeicoesTitle = "Hábito de realizar no mínimo as três refeições principais do dia";
const refeicoesTVTitle = "Hábito de realizar as refeições assistindo à televisão";

const nutritionFoodItems: NutritionFoodDictionaryItems[] = [
  {
    age: Age["0m6m"],
    goodItems: [
      { id: NutritionFoodID["0m6m_1"], title: "Aleitamento humano exclusivo", description: aleitamentoExclusivo }
    ],
    badItems: []
  },
  {
    age: Age["6m2a"],
    goodItems: [
      { id: NutritionFoodID["6m2a_1"], title: "Aleitamento humano continuado", description: aleitamentoContinuado },
      { id: NutritionFoodID["6m2a_2"], title: "Diversidade alimentar mínima", description: diversidadeAlimentar },
      { id: NutritionFoodID["6m2a_3"], title: "Frequência mínima e consistência adequada", description: frequencia },
      { id: NutritionFoodID["6m2a_4"], title: "Consumo de alimentos ricos em ferro", description: ferro },
      { id: NutritionFoodID["6m2a_5"], title: "Consumo de alimentos ricos em vitamina A", description: vitaminaA }
    ],
    badItems: [
      { id: NutritionFoodID["6m2a_6"], title: ultraTitle, description: ultra },
      { id: NutritionFoodID["6m2a_7"], title: hamburguerTitle, description: hamburguer },
      { id: NutritionFoodID["6m2a_8"], title: refriTitle, description: refri },
      { id: NutritionFoodID["6m2a_9"], title: miojoTitle, description: miojo },
      { id: NutritionFoodID["6m2a_10"], title: docesTitle, description: doces }
    ]
  },
  {
    age: Age["2a5a"],
    goodItems: [
      { id: NutritionFoodID["2a5a_1"], title: tresRefeicoesTitle, description: tresRefeicoes },
      { id: NutritionFoodID["2a5a_3"], title: "Consumo de feijão", description: feijao },
      { id: NutritionFoodID["2a5a_4"], title: "Consumo de fruta", description: frutas },
      { id: NutritionFoodID["2a5a_5"], title: "Consumo de verduras e legumes", description: verduras }
    ],
    badItems: [
      { id: NutritionFoodID["2a5a_2"], title: refeicoesTVTitle, description: refeicoesTV },
      { id: NutritionFoodID["2a5a_10"], title: ultraTitle, description: ultra },
      { id: NutritionFoodID["2a5a_6"], title: hamburguerTitle, description: hamburguer },
      { id: NutritionFoodID["2a5a_7"], title: refriTitle, description: refri },
      { id: NutritionFoodID["2a5a_8"], title: miojoTitle, description: miojo },
      { id: NutritionFoodID["2a5a_9"], title: docesTitle, description: doces }
    ]
  },
  {
    age: Age["5a10a"],
    goodItems: [
      { id: NutritionFoodID["5a10a_1"], title: tresRefeicoesTitle, description: tresRefeicoes },
      { id: NutritionFoodID["5a10a_3"], title: "Consumo de feijão", description: feijao },
      { id: NutritionFoodID["5a10a_4"], title: "Consumo de fruta", description: frutas },
      { id: NutritionFoodID["5a10a_5"], title: "Consumo de verduras e legumes", description: verduras }
    ],
    badItems: [
      { id: NutritionFoodID["5a10a_2"], title: refeicoesTVTitle, description: refeicoesTV },
      { id: NutritionFoodID["5a10a_10"], title: ultraTitle, description: ultra },
      { id: NutritionFoodID["5a10a_6"], title: hamburguerTitle, description: hamburguer },
      { id: NutritionFoodID["5a10a_7"], title: refriTitle, description: refri },
      { id: NutritionFoodID["5a10a_8"], title: miojoTitle, description: miojo },
      { id: NutritionFoodID["5a10a_9"], title: docesTitle, description: doces }
    ]
  },
  {
    age: Age["10a19a"],
    goodItems: [
      { id: NutritionFoodID["10a19a_1"], title: tresRefeicoesTitle, description: tresRefeicoes },
      { id: NutritionFoodID["10a19a_3"], title: "Consumo de feijão", description: feijao },
      { id: NutritionFoodID["10a19a_4"], title: "Consumo de fruta", description: frutas },
      { id: NutritionFoodID["10a19a_5"], title: "Consumo de verduras e legumes", description: verduras }
    ],
    badItems: [
      { id: NutritionFoodID["10a19a_2"], title: refeicoesTVTitle, description: refeicoesTV },
      { id: NutritionFoodID["10a19a_10"], title: ultraTitle, description: ultra },
      { id: NutritionFoodID["10a19a_6"], title: hamburguerTitle, description: hamburguer },
      { id: NutritionFoodID["10a19a_7"], title: refriTitle, description: refri },
      { id: NutritionFoodID["10a19a_8"], title: miojoTitle, description: miojo },
      { id: NutritionFoodID["10a19a_9"], title: docesTitle, description: doces }
    ]
  }
];

export default nutritionFoodItems;
