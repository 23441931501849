import { Fragment, h, JSX } from "preact";
import { AppSection } from "@_types/Enums";

type textNoteAboutPartialDataProps = {
  type: AppSection;
};

export default function TextNoteAboutPartialData(
  props: textNoteAboutPartialDataProps
): JSX.Element {
  let text = '';
  if(props.type === AppSection.NUTRITION_CLASS) {
    text = "* Os dados de 2024 são parciais e foram obtidos no SISVAN em 09/12/2024.";
  } else if(props.type === AppSection.NUTRITION_FOOD) {
    text = "* Os dados de 2024 são parciais e foram obtidos no SISVAN em 09/12/2024.";
  }

  return <Fragment>{text}</Fragment>
}