import { Fragment, h, JSX } from "preact";
import ButtonIcon, { ButtonIconLink } from "@components/ButtonIcon";

export default function AppFooter(): JSX.Element {
  return (
    <Fragment>
      <footer class="container">
        <h3>Mais Informações?</h3>
        <div>
          <ButtonIconLink label={"Entre em contato com o Instituto"} icon={'contact'} theme={'primary'} address={"https://desiderata.org.br/contato/"} />
          <ButtonIconLink label={"Baixe as notas técnicas completas"} icon={'pdf'} theme={'light'} address={"/assets/docs/panorama-obesidade-notas-tecnicas-2024-dezembro-20.pdf"} />
          <ButtonIconLink label={"LGPD"} icon={'link'} theme={'light'} address={"/lgpd.html"} />
          <span>
                Desenvolvido por{" "}
                <a href="https://datapedia.info" target="_blank" rel="noreferrer">
                  Datapedia.info
                </a>
          </span>
        </div>
      </footer>

      <div className={"obesidadeinfantil-rodape"}>
        <div className={"container"}>
          <div>
            <a href={"https://www.obesidadeinfantil.org.br/"}>
              <img
                alt={"Logo Obesidade Infantil"}
                src={"/assets/img/logos/obesidadeinfantil-logo-branca.png"}
                width={342}
                height={117}
              />
            </a>

            <a href={"https://desiderata.org.br/"} target={"_blank"} rel="noreferrer">
              <img
                alt={"Logo Instituto Desiderata"}
                src={"/assets/img/logos/desiderata-logo-branca.png"}
                width={210}
                height={108}
              />
            </a>
          </div>

          <div>
            <ul>
              <li>
                <a
                  href={"https://www.instagram.com/institutodesiderata/"}
                  title={"Instagram"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img alt={"Instagram"} src={"/assets/img/social/instagram.png"} width={30} height={30} />
                </a>
              </li>
              <li>
                <a
                  href={"https://www.facebook.com/institutodesiderata/"}
                  title={"Facebook"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img alt={"Facebook"} src={"/assets/img/social/facebook.png"} width={30} height={30} />
                </a>
              </li>
              <li>
                <a
                  href={"https://www.youtube.com/channel/UCf1gyibTyKLAGsgG_zH4kOw"}
                  title={"Youtube"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img alt={"Youtube"} src={"/assets/img/social/youtube.png"} width={30} height={30} />
                </a>
              </li>
              <li>
                <a
                  href={"https://www.linkedin.com/company/instituto-desiderata"}
                  title={"Linkedin"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img alt={"Linkedin"} src={"/assets/img/social/linkedin.png"} width={30} height={30} />
                </a>
              </li>
            </ul>

            <address>
              Rua Humaitá, 275 - 7° andar
              <br />
              Humaitá, Rio de Janeiro - RJ
              <br />
              CEP 22261-005
            </address>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
