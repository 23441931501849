import { h, JSX } from "preact";

/*
 * Pequena tag de conteúdo a ser inserida no Banner para afirmar que RJ e Niterói já possuem projetos de lei
 */
export default function MobilizeBannerTag(): JSX.Element {
  return (
    <span className={"mobilize-banner-tag"}>
      <a
        href="https://leidainfanciasaudavel.org.br/rio/"
        target="_blank"
        rel={"noreferrer"}
        title={"Clique e saiba mais sobre o PL no Rio de Janeiro"}
      >
        Rio de Janeiro
      </a>{" "}
      e{" "}
      <a
        href="https://leidainfanciasaudavel.org.br/niteroi/"
        target="_blank"
        rel={"noreferrer"}
        title={"Clique e saiba mais sobre o PL em Niterói"}
      >
        Niterói
      </a>{" "}
      já possuem projetos de lei (PL)!
    </span>
  );
}
